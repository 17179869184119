<template>
    <v-container
        class="fill-height"
        fluid
    >
        <v-row>
            <v-col
                class="d-flex justify-center"
            >
                <v-card
                    max-width="500"
                    class="flex-grow-1 elevation-12"
                >
                    <div
                        class="d-flex justify-end"
                        style="position:absolute;width:100%;top:-56px;"
                    >
                        <v-icon
                            color="success"
                            class="mx-3 my-3"
                            size="32"
                        >
                            mdi-beta
                        </v-icon>
                    </div>
                    <v-card-text>
                        <template v-if="alertType != 'success'">
                            <v-text-field
                                v-if="input == 'textField'"
                                label="Введите ваш e-mail"
                                prepend-icon="mdi-email"
                                hide-details
                                type="text"
                                v-model="email"
                                @focus="focus"
                                v-on:keydown.enter="getAccess"
                            />

                            <v-autocomplete
                                ref="autocomplete"
                                v-if="input == 'autocomplete'"
                                label="Введите ваш e-mail"
                                prepend-icon="mdi-email"
                                hide-details
                                hide-no-data
                                append-icon=""
                                :items="emails"
                                :search-input.sync="search"
                                @blur="blur()"
                                @focus="focus"
                                v-on:keydown.enter="getAccess"
                            ></v-autocomplete>
                        </template>

                        <v-alert
                            v-if="alertMessage"
                            :type="alertType"
                            class="mt-3 mb-0"
                        >{{ alertMessage }}
                        </v-alert>

                        <v-alert
                            v-if="showKeepassAlert"
                            class="mb-0"
                        >
                            Ключ для входа не найден. Войдите через e-mail, чтобы создать ключ.
                        </v-alert>
                    </v-card-text>

                    <v-card-actions class="pb-4">
                        <div
                            v-if="alertType == 'success' && !repeatLoading"
                            class="text-body-1 black-color ml-2"
                        >
                            я не получил,
                            <span
                                class="send-again-link green--text text--darken-4 font-weight-medium"
                                @click="getAccess($event, true)"
                            >
                                отправить ещё раз
                            </span>
                        </div>
                        <v-progress-circular
                            v-if="repeatLoading"
                            indeterminate
                            color="green"
                            class="mx-auto"
                        ></v-progress-circular>
                        <v-spacer v-if="!showGetPasskey"></v-spacer>
                        <v-btn
                            v-if="accessBtn && alertType != 'success'"
                            color="success"
                            :class="{'ml-2 ml-sm-10':showGetPasskey}"
                            @click="getAccess"
                            :loading="loading"
                        >
                            Получить доступ
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="showGetPasskey"
                            color="success"
                            dark
                            fab
                            small
                            class="mr-2 mr-sm-10"
                            :loading="getPasskeyLoading"
                            @click="getPasskey"
                        >
                            <v-icon dark>
                                mdi-fingerprint
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {arrayBufferToBase64, recursiveBase64StrToArrayBuffer} from '@/passkeyFns'
import {mapActions, mapMutations} from 'vuex'

export default {

    data() {
        return {
            loading: false,
            repeatLoading: false,
            getPasskeyLoading: false,
            email: '',
            emails: [],
            alertType: '',
            alertMessage: '',
            input: 'autocomplete',
            search: '',
            accessBtn: true,
            showGetPasskey: false,
            showKeepassAlert: false
        }
    },


    watch: {
        email(value) {
            if (!value) this.input = 'autocomplete'
        }
    },


    created() {
        this.setAuthEmail()
        this.checkShowGetPasskey()
    },


    methods: {

        ...mapActions(['request']),

        ...mapMutations({'changeAuthorized': 'authorized'}),

        async getPasskey() {
            this.showKeepassAlert = false
            if (!this.showGetPasskey) return

            this.getPasskeyLoading = true

            let getArgs = await this.request({
                controller: 'PasskeyGet',
                action: 'getGetArgs'
            })
            if (!getArgs) {
                this.getPasskeyLoading = false
                return
            }

            recursiveBase64StrToArrayBuffer(getArgs)
            const credential = await navigator.credentials.get(getArgs)
                .catch(() => {
                })
            if (!credential) {
                this.getPasskeyLoading = false
                return
            }

            const authenticatorAttestationResponse = {
                id: credential.rawId ? arrayBufferToBase64(credential.rawId) : null,
                clientDataJSON: credential.response.clientDataJSON ? arrayBufferToBase64(credential.response.clientDataJSON) : null,
                authenticatorData: credential.response.authenticatorData ? arrayBufferToBase64(credential.response.authenticatorData) : null,
                signature: credential.response.signature ? arrayBufferToBase64(credential.response.signature) : null,
                userHandle: credential.response.userHandle ? arrayBufferToBase64(credential.response.userHandle) : null
            }

            this.request({
                controller: 'PasskeyGet',
                action: 'processGet',
                ...authenticatorAttestationResponse
            }).then(response => {
                if (response) window.location.replace('/')
            }).catch(() => {
                this.showKeepassAlert = true
            }).finally(() => {
                this.getPasskeyLoading = false
            })
        },

        setAuthEmail() {
            let authEmails = localStorage.getItem('authEmails')
            this.emails = authEmails ? authEmails.split(',') : []
            let lastAuthEmail = localStorage.getItem('lastAuthEmail')
            if (lastAuthEmail) {
                this.email = lastAuthEmail
                this.input = 'textField'
            }
        },

        async checkShowGetPasskey() {
            if (
                !window.PublicKeyCredential ||
                !PublicKeyCredential.isConditionalMediationAvailable
            )
                return false
            const isCMA = await PublicKeyCredential.isConditionalMediationAvailable()
            if (!!isCMA)
                this.showGetPasskey = true
        },

        blur() {
            this.email = this.search
            if (this.email != '')
                this.input = 'textField'
        },

        focus() {
            this.accessBtn = true
            this.alertType = ''
            this.alertMessage = ''
        },

        getAccess(event, repeat = false) {
            this.showKeepassAlert = false
            this.accessBtn = true
            if (this.input == 'autocomplete')
                this.email = this.search
            if (!this.email) return false

            if (repeat) {
                this.repeatLoading = true
            } else {
                this.alertType = ''
                this.alertMessage = ''
            }

            this.input = 'textField'

            this.loading = true

            return this.$store.dispatch('request', {
                controller: 'Auth',
                action: 'getAccess',
                email: this.email.trim()
            }).then(() => {
                if (!this.emails.includes(this.email))
                    this.emails.push(this.email)
                localStorage.setItem('authEmails', this.emails.join())
                localStorage.setItem('lastAuthEmail', this.email)

                if (this.$refs.autocomplete)
                    this.$refs.autocomplete.blur()
                this.alertType = 'success'
                this.alertMessage = 'Мы отправили вам особую ссылку для входа, откройте письмо и просто кликните по ссылке для входа'
                this.accessBtn = false
            }).catch(e => {
                this.alertType = 'error'
                this.alertMessage = e.message
            }).finally(() => {
                this.loading = false
                this.repeatLoading = false
            })
        }

    }
}
</script>

<style lang="scss">
.send-again-link {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>